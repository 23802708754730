// import axios from 'axios';
// import AWS from 'aws-sdk';
// import AWS from 'aws-sdk';
import instance from './axiosUtils';

async function getAWSCredentials(url) {
    try {
        const response = await instance.get(url);
        return response.data;
    } catch (error) {
        console.error('Error fetching AWS credentials:', error);
        throw error;
    }
}

async function uploadImageToS3(credentials, bucketName, key, file) {
    const { accessKeyId, secretAccessKey, sessionToken } = credentials;
    AWS.config.update({
        accessKeyId,
        secretAccessKey,
        sessionToken,
        region: 'us-east-1' // Update to your region
    });

    const s3 = new AWS.S3();

    const params = {
        Bucket: bucketName,
        Key: key,
        Body: file,
        ContentType: file.type,
        ACL: 'public-read',
    };

    try {
        const data = await s3.upload(params).promise();
        return data;
    } catch (error) {
        console.error('Error uploading image to S3:', error);
        throw error;
    }
}
function generateUniqueKey(baseString) {
    const currentDate = new Date().toISOString().split('T')[0]; // Get current date in YYYY-MM-DD format
    const randomSuffix = Math.random().toString(36).substring(2, 8); // Generate a 6-character random string
    return `${currentDate}/${randomSuffix}-${baseString}`;
}

/**
 * 上传图片到指定的S3桶
 * 
 * @param {string} url - 获取AWS凭证的URL
 * @param {string} bucketName - S3桶的名称
 * @param {string} key - 上传到S3的文件键
 * @param {File} file - 要上传的文件
 * @returns {Promise<Object>} 返回上传结果的Promise对象
 * @throws {Error} 上传过程中发生的错误
 */
export async function uploadImage(url, bucketName, key, file) {
    try {
        const credentials = await getAWSCredentials(url);
        const result = await uploadImageToS3(credentials, bucketName, generateUniqueKey(key), file);
        return result.Location;
    } catch (error) {
        console.error('Error in uploadImage:', error);
        throw error;
    }
}